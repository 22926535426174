import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import WithdrawPage from '../../components/Content/Withdraw/WithdrawPage';
import CookieDisableComponent from '../../components/shared/CookieDisableError';

const Withdraw = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const { breadcrumbs } = pageContext;

  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  if (!localError) {
    return (
      <Layout>
        <SEO title={metaTitle} description={metaDesc} pathname={currentPage.path} breadcrumbs={breadcrumbs} />
        <WithdrawPage />
      </Layout>
    );
  }

  return (<CookieDisableComponent />);
};

export default Withdraw;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`;
