import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { breakpoints, color, reset, typography, sizing } from '../../utils/style';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PaymentSystems from '../Content/PaymentSystems';
import { getAuthUserName } from '../Auth/Utils';
import StoreContext from './Context/StoreContext';
import Parallax from './Parallax';
import GetWindowWidth from './GetWindowWidth';

const Content = styled('div')`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: transparent;
  &.inter-blocked,
  &.popup-blocked {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: -1;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: -1;
    }
  }
  &.popup-blocked {
    .nav-wrap {
      position: static;
    }
    #app-header,
    .logo {
      z-index: 0;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    &.open {
      position: fixed;
      background-image: none;
      z-index: 99;
      background-color: ${color.lightPurple};
      div > div {
        background-image: none;
      }
    }
  }
`;

const ContentWrapper = styled('div')`
  max-width: ${sizing.maxWidth};
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  position: relative;
`;

const Main = styled('main')`
  flex-grow: 1;
  padding: 0 16px;
  @media (max-width: ${breakpoints.md}) {
    margin-top: 10px;
  }
`;

// REFACTOR
// Parallax should be refactored like one of tasks.
export default ({ children, postCategory }) => {
  const [username, setUsername] = useState(getAuthUserName());
  const windowWidth = GetWindowWidth();
  const isDesktop = windowWidth > 960;

  return [
    <Global
      key="reset"
      styles={css`
        ${reset} ${typography}
      `}
    />,
    <Helmet key="app-head">
      <html lang="th" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Helmet>,
    <StoreContext.Provider
      key="login-context-provider"
      value={{ username, setUsernameCallback: setUsername, postCategory }}
    >
      <Content key="app-content" id="all-content">
        <ContentWrapper>
          <Header key="app-header" location="/" />
          <Main key="app-content">{children}</Main>
          <PaymentSystems />
          <Footer key="app-footer" />
          {isDesktop && <Parallax />}
        </ContentWrapper>
      </Content>
    </StoreContext.Provider>,
  ];
};
