import React from 'react';
import styled from '@emotion/styled';
import ParallaxHome from './Parallax';
import LogoIcon from '../../icons/svg/LogoIcon';
import { breakpoints } from '../../utils/style';

const Wrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 1, 27);
  color: #ffd10a;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 20px;
  box-sizing: border-box;
  overflow: hidden;
  
  .parallax-container {
    position: fixed;
  }
  
  .msg-text {
    margin-top: 50px;
    font-size: 32px;
    z-index: 20;
  }  
  
  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    text-align: center;
        
    .logo {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const CookieDisableComponent = () => (

  <Wrapper>
    <div className="logo">
      <LogoIcon />
    </div>

    <p className="msg-text">คุณต้องเปิดใช้งานคุกกี้</p>

    <div className="parallax-container">
      <ParallaxHome />
    </div>
  </Wrapper>
);

export default CookieDisableComponent;
